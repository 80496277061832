import Vue from 'vue'
import Vuex from 'vuex'

import Usuarios from './modules/home/users'
/* import Loja from './modules/loja' */
/* import Sender from './modules/home/sender'
import Deliveries from './modules/home/deliveries' */
import Home from './modules/home'
import Extrafield from './modules/extrafield'
import Senders from './modules/sender'
import Deliveries from './modules/deliveries'
import Maths from './modules/maths'

import { api } from '@/services'

// Modules

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: JSON.parse(localStorage.user || '{}'),
    token: localStorage.token || undefined,
    edit: [],
    extraFields: [],
    rules: [],
    packageSave: {},
    groupID: '',
    dashboard: [
      {
        key: 'users',
        keyRouter: 'usuarios.users',
        icon: { key: 'group', custom: true },
        title: 'Usuários',
        layout: 'table'
        // only: ['Super Admin', 'Vulpee']
      },
      {
        key: 'sender',
        keyRouter: 'usuarios.sender',
        icon: { key: 'storefront', custom: false },
        title: 'Clientes',
        layout: 'table'
      },
      {
        key: 'deliveries',
        keyRouter: 'usuarios.deliveries',
        icon: { key: 'motorcycle', custom: false },
        title: 'Entregadores',
        layout: 'table'
      },
      {
        key: 'maths',
        keyRouter: 'usuarios.maths',
        icon: { key: 'moped', custom: false },
        title: 'Corridas',
        layout: 'table'
      },
      {
        key: 'extrafield',
        noMenu: true,
        noRoute: true
      }
    ]
  },
  getters: {
    userName: (state) => (state.user.name === undefined ? '' : state.user.name),
    userEmail: (state) =>
      state.user.email === undefined ? '' : state.user.email,
    isLogged: (state) =>
      Object.keys(state.user).length !== 0 && state.token !== undefined,
    isAdmin: (state) => state.user.name === 'Super Admin',
    extraFields: (state) => state.extraFields,
    edit: (state) => state.edit,
    groupID: (state) => state.groupID,
    rules: (state) => state.rules
  },
  mutations: {
    login: (state, payload) => {
      state.user = payload.user
      state.token = payload.token
    },
    logout: (state) => {
      state.user = {}
      state.token = undefined
    },
    edit: (state, payload) => {
      state.edit.push(payload)
    },
    getExtraFields: (state, payload) => {
      state.extraFields = payload
    },
    getGroupId: (state, payload) => {
      state.groupID = payload
    },
    rules: (state, payload) => {
      state.rules = payload
    },
    packageSave: (state, payload) => {
      state.packageSave = payload
    }
  },
  actions: {
    login: (context, payload) => {
      localStorage.user = JSON.stringify(payload.user)
      localStorage.token = payload.token
      api.defaults.headers.common.Authorization = `Bearer ${payload.token}`
      context.commit('login', payload)
    },
    logout: (context) => {
      localStorage.clear()
      delete api.defaults.headers.common.Authorization
      context.commit('logout')
    },
    edit: (context, payload) => {
      context.commit('edit', payload)
    },
    getExtraFields: (context, payload) => {
      context.commit('getExtraFields', payload)
    },
    getGroupId: (context, payload) => {
      context.commit('getGroupId', payload)
    },
    rules: (context, payload) => {
      context.commit('rules', payload)
    },
    packageSave: (context, payload) => {
      context.commit('packageSave', payload)
    }
  },
  modules: {
    /* loja: Loja, */
    users: Usuarios,
    home: Home,
    extrafield: Extrafield,
    sender: Senders,
    deliveries: Deliveries,
    maths: Maths
  }
})
