// import config from "@/config";

export default {
  namespaced: true,
  state: {
    maths: [],
    loading: false,
    itemsKey: 'maths',
    title: 'Corridas',
    name: 'Corridas',
    hasEdit: true,
    createPacket: true,
    radios: true,
    get: {
      urn: 'object/index',
      body: {
        wheres: [{
          type: 'where',
          field: 'type',
          condition: '=',
          value: 'corrida'
        }]
      },
      type: 'post',
      response: 'objects'
    },
    hasExtraField: true,
    fields: [
      { key: 'type', label: 'Tipo' },
      { key: 'key', label: 'Chave' },
      { key: 'description', label: 'Descrição' }
    ],
    form: {}
  },
  mutations: {
    set: (state, payload) => {
      state.maths = payload
      state.loading = false
    },
    unshift: (state, payload) => {
      state.maths.unshift(payload)
    },
    swap: (state, payload) => {
      state.maths.forEach((element, index) => {
        if (element.id === payload.id) {
          state.maths.splice(index, 1, payload)
        }
      })
    },
    remove: (state, payload) => {
      state.maths = state.maths.filter((a) => a.id !== payload)
    }
  },
  actions: {
    init: (context, payload) => {
      context.commit('set', payload)
    },
    add: (context, payload) => {
      context.commit('unshift', payload)
    },
    edit: (context, payload) => {
      context.commit('swap', payload)
    },
    remove: (context, payload) => {
      context.commit('remove', payload)
    }
  }
}
