export default {
  namespaced: true,
  state: {
    // Info
    // btnLabel: 'Salvar',
    loading: false,
    itemsKey: 'users',
    title: 'Todos os usuários',
    name: 'Usuarios',
    btnLabel: 'Próximo',
    hasEdit: true,
    createPacket: true,
    radios: true,
    hasExtraField: true,
    // API Get index
    get: {
      // eslint-disable-next-line quotes
      urn: 'user/index/?group_keys=[Sender,Deliveries]',
      response: 'users'
    },
    get2: {
      urn: 'group/public/index',
      response: 'groups'
    },
    fields: [
      { key: 'name', label: 'Nome' },
      { key: 'email', label: 'Email' },
      { key: 'cpf', label: 'CPF' },
      { key: 'telephone', label: 'Telefone' }
    ]
  },
  mutations: {
    set: (state, payload) => {
      state.users = payload
      state.loading = false
    },
    unshift: (state, payload) => {
      state.users.unshift(payload)
    },
    swap: (state, payload) => {
      state.users.forEach((element, index) => {
        if (element.id === payload.id) {
          state.users.splice(index, 1, payload)
        }
      })
    },
    remove: (state, payload) => {
      state.users = state.users.filter((a) => a.id !== payload)
    }
  },
  actions: {
    init: (context, payload) => {
      context.commit('set', payload)
    },
    add: (context, payload) => {
      context.commit('unshift', payload)
    },
    edit: (context, payload) => {
      context.commit('swap', payload)
    },
    remove: (context, payload) => {
      context.commit('remove', payload)
    }
  }
}
