<template>
  <div class="main">
    <div class="h-separator">
      <span></span>
      <span class="title">Item</span>
      <span></span>
    </div>
    <b-form @submit="onSubmit" ref="form">
      <b-row>
        <b-col cols="3">
          <b-form-group>
            <div class="h-separator">
              <label for="input-add-product" class="title">Código NCM *</label>
              <span></span>
            </div>
            <b-form-input
              id="input-add-product"
              v-model="form.ncm_code"
              type="number"
              placeholder="Código NCM"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group>
            <div class="h-separator">
              <label
                v-b-tooltip.html
                title="<a target='_blank' href='https://efi.correios.com.br/app/ncm/ncm.php'>Códigos Harmonizados</a>"
                for="input-add-harmonized"
                class="title"
                >Código Harmonizado *</label
              >
              <span></span>
            </div>
            <b-form-input
              id="input-add-harmonized"
              v-model="form.harmonized_Code"
              type="number"
              placeholder="Código Harmonizado"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group>
            <div class="h-separator">
              <label for="input-add-description" class="title"
                >Descrição do produto *</label
              >
              <span></span>
            </div>
            <b-form-input
              id="input-add-description"
              v-model="form.description"
              type="text"
              placeholder="Descrição do produto"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group>
            <div class="h-separator">
              <label for="input-add-sku" class="title">Código Sku *</label>
              <span></span>
            </div>
            <b-form-input
              id="input-add-sku"
              v-model="form.sku_code"
              type="text"
              placeholder="Código Sku"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group>
            <div class="h-separator">
              <label for="input-add-value" class="title"
                >Valor Unitário ($) *</label
              >
              <span></span>
            </div>
            <b-form-input
              id="input-add-unit"
              v-model="form.unit"
              type="number"
              step=".01"
              placeholder="Valor em Dólares"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-checkbox
            v-model="form.lithium_battery"
            name="check-button"
            switch
          >
            Bateria de lítio
          </b-form-checkbox>
        </b-col>
        <b-col cols="2">
          <b-form-checkbox v-model="form.perfumes" name="check-button" switch>
            Perfumes
          </b-form-checkbox>
        </b-col>
      </b-row>
      <b-button type="submit" variant="primary">Salvar</b-button>
    </b-form>
  </div>
</template>
<script>
import api from '@/services'
import { toast } from '@/utils'

export default {
  data () {
    return {
      form: {
        ncm_code: '',
        harmonized_Code: '',
        description: '',
        sku_code: '',
        value: '',
        weight: '',
        quantity: '',
        totalValue: '',
        lithium_battery: false,
        perfumes: false
      }
    }
  },
  watch: {
    'form.unit': {
      handler (value) {
        this.form.totalValue = value * (this.form.quantity || 1)
        this.form.value = value
      },
      deep: true
    },
    'form.quantity': {
      handler (value) {
        this.form.totalValue = value * (this.form.value || 0)
      },
      deep: true
    },
    'form.ncm_code': {
      handler (value) {
        this.form.harmonized_Code = value.slice(0, -2)
      },
      deep: true
    }
  },
  methods: {
    onSubmit (event) {
      event.preventDefault()
      api.post('item/create', this.form).then(
        (response) => {
          console.log(response)
          if (response.status === 200) {
            this.$store.dispatch('itens/add', response.data.item)

            // Hide the modal manually
            this.$nextTick(() => {
              this.$bvModal.hide('modal-3')
            })

            this.$emit('finish')

            toast(
              this,
              'success',
              'Item registrado',
              'Item foi registrado com sucesso'
            )
          }
        },
        (error) => {
          toast(
            this,
            'danger',
            'Item não pode ser registrado',
            error.message +
              (error.response.status === 422
                ? ' => ' + error.response.data.errors
                : '')
          )
          return Promise.reject(error)
        }
      )
    }
  }
}
</script>
<style lang="stylus" scoped>
@import '../../style/colors.styl'

.main
    //background-color light

    .h-separator
        display flex

        .title
            padding 0px 10px
            font-size 14px
            color light
            font-weight bold
            border-radius 1rem
            background primary

        span
            align-self center

        span:last-child
            border 1px dashed primary
            height 1px

        span:last-child
            flex-grow 1

    .custom-switch
        margin-top 40px

    button
        float right
        width 100px
        background-color #4472C4
        font-size 14px
</style>
