export const toast = (vue, variant, title, msg) => {
  vue.$bvToast.toast(msg, {
    title: title,
    variant: variant,
    solid: true
  })
}

// Convert data to array options for a select field
export const toOptions = (data, key) => {
  const options = []
  data.forEach((element) => {
    options.push({
      value: element[key.value],
      text: element[key.text]
    })
  })
  return options
}

// Format Date
export const dateFormat = (date) => {
  return new Date(new Date(date) - new Date().getTimezoneOffset() * 60000)
    .toISOString()
    .replace(/-/g, '/')
    .replace('T', ' - ')
    .replace('Z', '')
    .slice(0, -4)
}

// String UpperCase
export const upperCase = (string) => {
  return string.toUpperCase()
}

// String UpperCase
export const captalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

/// /////////////////////////////////////////
// Base Mutations for modules

export const defaultMutations = {
  set: (state, payload) => {
    state.items = payload
  },
  unshift: (state, payload) => {
    state.items.unshift(payload)
  },
  swap: (state, payload) => {
    state.items.forEach((element, index) => {
      if (element.id === payload.id) {
        state.items.splice(index, 1, payload)
      }
    })
  }
}

// Base Actions for modules
export const defaultActions = {
  init: (context, payload) => {
    context.commit('set', payload)
  },
  add: (context, payload) => {
    context.commit('unshift', payload)
  },
  edit: (context, payload) => {
    context.commit('swap', payload)
  }
}

// user to extra field
export const userToExtraFields = (item) =>
  Object.keys(item).reduce(
    (obj, field) =>
      field === 'extra_fields'
        ? Object.assign(
          {},
          obj,
          item.extra_fields.reduce(
            (extra, customFields) =>
              Object.assign({}, extra, {
                [customFields.key]:
                    customFields.value.length ? customFields.value[0] : ''
              }),
            {}
          )
        )
        : Object.assign({}, { [field]: item[field] }, obj),
    {}
  )

// Remove accents + lowercase
export const normalizeStr = (str) =>
  str
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')

export const errorFormater = (error) =>
  error.response.status === 500
    ? 'Erro interno no servidor'
    : [422, 404, 401, 400].includes(error.response.status)
      ? Array.isArray(error.response.data)
        ? error.response.data.reduce((s, m) => (s += JSON.stringify(m) + ' '), '')
        : error.response.data?.BringerResponse?.message ||
        'Erro desconhecido'
      : 'Erro desconhecido'

// Converter packet que api retorna para o fluxo de api
export const packetToSave = (state, p) => ({
  deliveries: userToExtraFields(
    state.deliveries.deliveries.find((u) => u.id === p.receiver_id)
  ),
  sender: userToExtraFields(
    state.sender.sender.find((u) => u.id === p.sender_id)
  ),
  package: {
    id: p.id,
    send_id: p.send_id,
    external_customer_id: p.external_customer_id,
    parcel_number: p.packet_number,
    service: p.service,
    tax_modality: p.tax_modality,
    'type of_package': p.type_package
  },
  items: p.items.reduce((obj, i) => ({ ...obj, [i.item_id]: i.quantity }), {}),
  resume: {
    box: {
      height: p.box_height,
      length: p.box_lenght,
      weight: p.box_weight,
      width: p.box_width
    },
    frete: p.freight_value,
    insuranceValue: p.insurance_value,
    unit_dimen: p.unit_dimen,
    unit_weight: p.unit_weight
  }
})
