import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import Dashboard from '../views/Dashboard/index.vue'
import Home from '../views/Dashboard/Home.vue'
import Table from '../views/Dashboard/Table.vue'
import Cards from '../views/Dashboard/Cards.vue'
// Recover Password
/* import RecoverPassword from '../views/RecoverPassword.vue' */

// Recover Confirm
/* import RecoverConfirm from '../views/RecoverConfirm.vue' */

// New Password
import NewPassword from '../views/NewPassword.vue'

// Password Reseted
import PasswordReseted from '../views/PasswordReseted.vue'

Vue.use(VueRouter)

const dashboardTabs = [
  {
    path: '/',
    name: 'Home',
    component: Home
  }
]

// Generate dynamic routes for dashboard
store.state.dashboard.forEach((tab) => {
  const path = tab.root ? '/' : tab.key

  /*
  if (
    tab.only &&
    !store.state.user.groups.reduce(
      (v, g) => v || tab.only.includes(g.key),
      false
    )
  ) {
    return;
  }
  */

  if (tab.noRoute) return

  dashboardTabs.push(
    ...[
      {
        // Index
        path: path,
        name: tab.title,
        component: tab.layout === 'cards' ? Cards : Table,
        props: { module: tab.key },
        beforeEnter: (to, from, next) => {
          if (
            tab.only &&
            !store.state.user.groups.reduce(
              (v, g) => v || tab.only.includes(g.key),
              false
            )
          ) {
            next({ name: 'Home' })
            return
          }
          next()
        }
      }
    ]
  )
})

const r = (k) => k.key.includes('index.user.api') || store.getters.rules.length > 0

const routes = [

  {
    path: '/',
    component: Dashboard,
    children: [
      ...dashboardTabs,
      {
        path: '/usuarios',
        name: 'usuarios.users',
        component: Table,
        props: { module: 'home.users' },
        beforeEnter: (to, from, next) => {
          if (
            store.getters.isAdmin || store.state.rules.some(r)
          ) {
            next()
            return
          }
          next({ name: 'Home' })
        }
      },
      {
        path: '/clientes',
        name: 'usuarios.sender',
        component: Table,
        props: { module: 'home.sender' },
        beforeEnter: (to, from, next) => {
          if (
            store.getters.isAdmin || store.state.rules.some(r)
          ) {
            next()
            return
          }
          next({ name: 'Home' })
        }
      },
      {
        path: '/entregadores',
        name: 'usuarios.deliveries',
        component: Table,
        props: { module: 'home.deliveries' },
        beforeEnter: (to, from, next) => {
          if (
            store.getters.isAdmin || store.state.rules.some(r)
          ) {
            next()
            return
          }
          next({ name: 'Home' })
        }
      },
      {
        path: '/corridas',
        name: 'usuarios.maths',
        component: Table,
        props: { module: 'home.maths' },
        beforeEnter: (to, from, next) => {
          if (
            store.getters.isAdmin || store.state.rules.some(r)
          ) {
            next()
            return
          }
          next({ name: 'Home' })
        }
      }
    ]
  },
  {
    path: '/sobre',
    name: 'Sobre',
    component: () => import('@/views/Sobre.vue')
  },
  {
    path: '/privacidade',
    name: 'Privacidade',
    component: () => import('@/views/Privacidade.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue')
  },
  /* {
    path: '/recover-password',
    name: 'RecoverPassword',
    component: RecoverPassword,
    beforeEnter: (to, from, next) => {
      if (store.getters.isLogged) {
        next({ path: '/' })
        return
      }
      next()
    }
  }, */
  // Recover Confirm
  /*  {
    path: '/recover-confirm',
    name: 'RecoverConfirm',
    component: RecoverConfirm,
    beforeEnter: (to, from, next) => {
      if (store.getters.isLogged) {
        next({ path: '/' })
        return
      }
      next()
    },
    props: (route) => ({ cpf: route.params.cpf })
  },
 */
  // Reset Password
  {
    path: '/new-password',
    name: 'NewPassword',
    component: NewPassword
  },

  // Password Reseted
  {
    path: '/password-reseted',
    name: 'PasswordReseted',
    component: PasswordReseted
  },

  { path: '*', redirect: '/' } // Not Found
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (['Login', 'Sobre', 'Privacidade'].includes(to.name) && store.getters.isLogged) {
    next({ name: 'Dashboard' })
    return
  }
  if (!['Login', 'Sobre', 'Privacidade'].includes(to.name) && !store.getters.isLogged) {
    next({ name: 'Sobre' })
    return
  }
  next()
})

export default router
