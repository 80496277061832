import Sender from './sender'
import Deliveries from './deliveries'
import Users from './users'

export default {
  namespaced: true,
  state: {
    title: 'Home',
    loading: false,
    dynamic: true
  },
  modules: {
    sender: Sender,
    deliveries: Deliveries,
    users: Users
  }
}
