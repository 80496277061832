<template>
  <Interface
    :title="state.title"
    :paths="createPacketPaths"
    :btnLabel="btnLabel"
    btnAsEvent
    @action="submit"
    noBtn
    :sortOptions="fieldsOptions"
    @sort="sort"
    @filter="filterSwitch"
    noSeparator
    @firstSelect="firstSelect"
  >
   <!--  <div @click="csv" class="title-btn-2">
      <i class="icon-btn material-icons">edit</i>
      <span class="label">Exportar Excel</span>
    </div> -->
    <b-table
      ref="table"
      sticky-header="100vh"
      style="padding-bottom: 16px"
      :busy="isBusy"
      :items="items"
      :fields="fieldsExtras"
      :per-page="perPage"
      :current-page="currentPage"
      :sort-by.sync="sortBy"
      :sort-desc="!sortDirection"
      :filter="filterData"
      :filter-function="filter"
      @filtered="onFiltered"
      show-empty
      :hover="!state.noHover"
      @row-clicked="onRowClicked"
    >
    <template
    v-if="state.hasEdit"
        v-slot:cell()="{ value, item, field: { key } }"
      >
        <template v-if="edit != item.id">{{ value }}</template>
          <template v-else>
            <b-form-input v-model="item[key]" />
          </template>
        </template>

        <template v-slot:cell(actions)="{ item: { id }, item }">
        <template
        >
          <i
            @click="remove(item)"
            class="material-icons btn-icon"
            style="background-color: red; border-radius: 20px;"
            >delete</i
          >
        </template>
        <template
        >
        <div
          class="btn-icons btn-actions"
        >
          <i
          style="border-radius: 20px;"
            v-if="edit !== item.id"
            @click="onEdit(id)"
            class="material-icons btn-icon"
            >edit</i
          >
          <i
            v-else
            style="border-radius: 20px;"
            @click="onSave(item, id)"
            class="material-icons btn-icon"
            >check</i
          >
        </div>
        </template>
      </template>
      <template v-if="checkboxs || radios" #cell(selected)="row">
        <!-- Enviado -->
        <template v-if="module.includes('packets') && row.item.url">
          <div class="btn-icons btn-actions">
            <i @click="openLink(row.item)" class="material-icons btn-icon"
              >picture_as_pdf</i
            >
            <!--<b-spinner v-else small label="Loading..."></b-spinner>-->
          </div>
        </template>
        <!-- Not checkbox -->
        <template v-else-if="notSetCheckbox(row.item)">
          <div></div>
        </template>
        <!-- Checkbox / Radio -->
        <template v-else>
          <div :class="classSelection">
            <div></div>
            <div
              :class="
                (isItemSelected(row.item) ? 'active ' : '') + 'fill-selected'
              "
            ></div>
          </div>
        </template>
      </template>

      <template #head()="key">
        <!-- Checkbox de selecionar tudo -->
        <template v-if="key.field.key == 'selected' && checkboxs">
          <div
            @click="switchSelection"
            class="checkbox d-inline-block align-top"
            style="background-color: #414d55"
          >
            <div></div>
            <div
              :class="(isAllRowsSelected ? 'active ' : '') + 'fill-selected'"
            ></div>
          </div>
          <span class="pl-4">Todos</span>
        </template>
        <template v-else-if="!['selected', 'actions'].includes(key.field.key)">
          <div>{{ key.label }}</div>
          <input
            v-show="isFiltering"
            type="text"
            class="form-control"
            :id="'filter-' + key.field.key"
            v-model="filterData[key.field.key]"
          />
        </template>
      </template>

      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner type="grow" label="Loading..." class="align-middle"></b-spinner>
          <strong class="pl-2">Carregando...</strong>
        </div>
      </template>
      <template #empty>
        <div class="text-center my-2">Não há registros até o momento.</div>
      </template>
      <template #emptyfiltered>
        <div class="text-center my-2">Não há registros até o momento.</div>
      </template>
    </b-table>
    <b-modal id="service-form" v-model="modalShow" size="xl" centered>
      <template #modal-header></template>
      <ServiceForm :user="userSelected"></ServiceForm>
      <template #modal-footer>
        <span></span>
      </template>
    </b-modal>
    <b-modal id="service" v-model="modalTable" size="xl" centered>
      <template #modal-header></template>
      <Services :user="userSelected"></Services>
      <template #modal-footer>
        <span></span>
      </template>
    </b-modal>
    <b-modal id="edit-packet" v-model="modalEdit" size="xl" centered>
      <template #modal-header>Editar Pacote</template>
      <PacketEdit :packet="item_selected_edit"></PacketEdit>
      <template #modal-footer>
        <span></span>
      </template>
    </b-modal>
    <b-modal id="remessa" v-model="modalRemessa" size="md" centered>
      <template #modal-header></template>
      <Remessa :codigo="remessa_cod" :total="remessa_total"></Remessa>
      <template #modal-footer>
        <span></span>
      </template>
    </b-modal>
    <b-modal id="items" v-model="modalItems" size="lg" centered>
      <template #modal-header></template>
      <ItemResume></ItemResume>
      <template #modal-footer>
        <span></span>
      </template>
    </b-modal>
    <b-modal id="redirectors" v-model="modalRedirectors" size="md" centered>
      <template #modal-header></template>
      <Redirectors :user="redirectorSelected"></Redirectors>
      <template #modal-footer>
        <span></span>
      </template>
    </b-modal>
    <b-pagination
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      size="md"
      align="right"
      class="my-0"
    ></b-pagination>
  </Interface>
</template>

<style lang="stylus">
@import '../../style/colors.styl';
@import '../../style/fonts.styl';
@import '../../style/mixins.styl';

.page-item.active .page-link
  background-color: #FFA53B;
  border-color: #FFA53B;

.table {
  color: greyLight !important;
  border-collapse: separate;
  border-spacing: 0 1em;
}

.table.b-table > thead > tr > .table-b-table-default, .table.b-table > tbody > tr > .table-b-table-default, .table.b-table > tfoot > tr > .table-b-table-default {
  background-color: light !important;
  color: black;
}

.table td, .table th, .table thead th {
  border: none;
  white-space: nowrap;
}

.table thead th {
  padding: 0 0.75rem;
  font-size: 20px;
}

.table tr {
  // background-color content-base-theme()
  background-color: primary;
  transition: 0.4s;
  height: 50px
  color #FFF
  cursor pointer

  &:focus {
    outline: none;
  }
}

radius = 10px;

.table tr td:first-child {
  border-top-left-radius: radius;
  border-bottom-left-radius: radius;
  width: 8%;
}

.table tr td:last-child {
  border-top-right-radius: radius;
  border-bottom-right-radius: radius;
}

.table th div {
  nowrap();
}

.table.b-table > tbody > .table-active, .table.b-table > tbody > .table-active > th, .table.b-table > tbody > .table-active > td {
  background-color: secondary !important;
}

.table.b-table tr {
  .btn-actions {
    min-width: 64px;
    display: inline;
    padding 5%
  }

  i {
    opacity: 0.9;
    transition: 0.3s;
    background-color: green;
    padding: 5px;
  }

  &:hover {
    i {
      opacity: 1;
      color: #FFF;
      cursor pointer
    }
  }
}

.ec {
  align-self: flex-end;
}

.btn {
  justify-content: space-between;
  margin-left: 10px;
  float: left;
}

.btn-service {
  display: inline;
  margin-left: 0px;
}

.checkbox, .radio {
  display: block;
  width: 25px;
  height: 25px;
  background-color: light;
  // padding 20px
  border: 1px solid black;
  cursor: pointer;
  margin-top: 4px;

  .fill-selected {
    min-height: 100%;
    // border-radius: 4px
    transition: 0.2s;
    transform: scale(0.2);
  }

  .active {
    background-color: green;
    width: auto;
    height: auto;
    transform: scale(1);
  }
}

.radio {
  &, .fill-selected {
    border-radius: 50%;
  }
}

#remessa___BV_modal_header_, #remessa___BV_modal_footer_ {
  display: none;
}

#remessa___BV_modal_content_ {
  background-color: light;
  border-radius: 8px;
}

.title-btn-2

  background-color blue
  color white
  border-radius 5px
  padding 6px 30px
  cursor pointer
  transition 0.2s
  margin-top -4.5rem
  position: absolute;
  top: 171px;
  right: 226px;
  font-family: Calibri;

  &:hover
        background-color alpha(primary, 0.8)

    span, .icon-btn
        vertical-align middle
        no-select()
</style>

<script>
import Interface from '@/components/Layout/Interface'
import { toOptions, errorFormater, toast, packetToSave, userToExtraFields, normalizeStr } from '@/utils'
import { api } from '../../services/index'

export default {
  components: {
    Interface
  },
  data: () => ({
    totalRows: 1,
    currentPage: 1,
    perPage: 8,
    sortBy: '',
    sortDirection: true,
    isFiltering: false,
    filterData: {},
    qtySelect: {},
    edit: null,
    modalShow: false,
    modalTable: false,
    modalItems: false,
    isLoading: false,
    modalPay: false,
    modalRemessa: false,
    modalRedirectors: false,
    redirectorSelected: null,
    remessa_cod: '',
    remessa_total: 0,
    pay_selected: null,
    item_selected_edit: null,
    modalEdit: false, // packet
    primaryFieldKey: 'id',
    selected: [],
    userSelected: {}
  }),
  props: {
    module: {
      type: String,
      required: true
    }
  },
  watch: {
    filterData: {
      handler (value) {
        Object.keys(value).forEach((key) => {
          if (value[key] === '') {
            console.log('empty: ' + value)
            delete value[key]
          }
        })
      },
      deep: true
    },
    item: {
      handler (value) {
        console.log(value)
      },
      deep: true
    }
  },
  created () {
    // console.log(this.items)
    this.totalRows = this.items.length
    if (['home.sender', 'home.deliveries'].includes(this.module)) {
      this.selected = [
        this.$store.state.packageSave[this.module.split('.')[1]]
      ].reduce((a, b) => (b ? [...a, b] : a), [])
    }

    /*  if (this.module === 'home.items') {
      this.qtySelect = this.$store.state.packageSave.items || {}
    } */
  },
  computed: {
    isBusy () {
      return this.state.externalModule
        ? this.$store.state[this.state.externalModule].loading
        : this.state.loading
      // return this.$store.state.home.loading
    },
    state () {
      const path = this.module.split('.')

      let state = this.$store.state

      path.forEach((e) => {
        state = state[e]
      })
      // console.log(state)
      return state
    },
    fields () {
    /*   if (this.state.dynamic) {
        return this.items
          .map((item) => Object.keys(item))
          .flat()
          .filter((value, index, self) => self.indexOf(value) === index)
      }
 */
      let baseFields =
        this.state.fields ||
        this.$store?.state[this.state.externalModule].fields

      // add extra fields to table
      if (this.state.hasExtraField) {
        const res = this.state.externalModule
          ? this.$store.state[this.state.externalModule][this.state.itemsKey]
          : this.state[this.state.itemsKey]
        baseFields = [...baseFields,
          ...(
            res?.[0].extra_fields || []
          ).map(e => ({
            // format to table field
            key: e.key,
            label: e.description
          })).filter(e => !['address', 'tax_delivery', 'vehicle', 'rate'].includes(e.key))
        ]
      }

      // Add cargo to redirector when admin
      /* if (this.$store.getters.isAdmin && this.module === 'users') {
        baseFields = [
          ...baseFields,
          {
            key: 'cargo',
            label: 'Cargo',
            formatter: (value, key, item) => {
              return item.groups.reduce(
                (v, g) => v || g.key === 'Vulpee',
                false
              )
                ? 'Premium'
                : 'Comum'
            }
          }
        ]
      } */

      return [...baseFields, { key: 'actions', label: '' }]
    /*   const res = [this.$store.state.home]
      return this.$route.name.split('.')[1] === 'sender' ? res[0].sender.fields : res[0].deliveries.fields */
    /**/
    },

    fieldsExtras () {
      return [...this.fields]
    },

    items () {
      let res = this.state.externalModule
        ? this.$store.state[this.state.externalModule][this.state.itemsKey]
        : this.state[this.state.itemsKey]

      if (!this.state.hasEdit) {
        res = res.map((item) => ({
          ...item,
          quantity: this.qtySelect[item.id] || 0,
          total: ((this.qtySelect[item.id] || 0) * item.value).toFixed(2)
        }))
      }

      res = this.state.hasExtraField ? res.map(userToExtraFields) : res
      res = this.state.filter ? res.filter(this.state.filter) : res

      return res
      // const res = [this.$store.state.home]
      /* const res = [this.$store.state.home]
      console.log(res[0].sender.sender)
      return this.$route.name.split('.')[1] === 'sender' ? res[0].sender.sender : res[0].deliveries.deliveries */
    },
    // stateItems
    fieldsOptions () {
      return (
        typeof this.fieldsExtras[0] !== 'string'
          ? toOptions(this.fieldsExtras, { value: 'key', text: 'label' }) // manual fields
          : this.fieldsExtras
      )
    },
    addParams () {
      return {
        item: null,
        action: this.state.routes.find((r) => r.key === 'add'),
        module: this.module
      }
    },
    isAllRowsSelected () {
      return (
        this.items.filter((item) => !this.notSetCheckbox(item)).length ===
          this.selected.length && this.selected.length
      )
    },
    checkboxs () {
      return this.state.checkboxs
    },
    radios () {
      return this.state.radios
    },
    classSelection () {
      return this.checkboxs ? 'checkbox' : this.radios ? 'radio' : ''
    },
    totalSelected () {
      return this.selected.reduce(
        (total, item) => total + item.internal_value * 1,
        0
      )
    },
    /*    btnLabel () {
      return this.$store.state.home?.sender.btnLabel || this.$store.state.home?.deliveries.btnLabel
    }, */
    btnLabel () {
      return this.$store.getters.isAdmin && this.module === 'packets_topay'
        ? this.state.btnLabelAdmin +
            (this.selected.length > 0 && this.module === 'packets_topay'
              ? ' ($ ' + this.totalSelected.toFixed(2) + ')'
              : '')
        : this.state.btnLabel +
            (this.selected.length > 0 && this.module === 'packets_topay'
              ? ' ($ ' + this.totalSelected.toFixed(2) + ')'
              : '')
    },
    createPacketPaths () {
      return this.state.createPacket
        ? [
          { name: 'Remetente', route: 'home.sender' },
          { name: 'Destinatário', route: 'home.deliveries' },
          { name: 'Usuários', route: 'home.users' }
        /*    { name: 'Detalhes do Pacote', route: 'sendbox.package' },
          { name: 'Itens', route: 'sendbox.items' },
          { name: 'Resumo', route: 'sendbox.resume' } */
        ]
        : undefined
    }
    /*  createPacketPaths () {
      return this.$store.state.home.sender.createPacket || this.$store.state.home.deliveries.createPacket ? [
        { name: 'Lojas', route: 'usuarios.sender' },
        { name: 'Entregadores', route: 'usuarios.deliveries' }
      ] : null
    } */
  },
  methods: {
    submit () {
      console.log('aqui')
    },
    itemSelectedIndex (item) {
      return this.selected.findIndex(
        (o) => o[this.primaryFieldKey] === item[this.primaryFieldKey]
      )
    },
    isItemSelected (item) {
      return !!(this.itemSelectedIndex(item) + 1)
    },
    switchSelection () {
      this.emitSelection(
        this.isAllRowsSelected
          ? []
          : this.items.filter((item) => !this.notSetCheckbox(item))
      )
    },
    emitSelection (v) {
      // this.$emit("onChange", v);
      this.selected = [...v]
    },
    onRowClicked (item) {
      /* if (!this.checkboxs && !this.radios) return */

      const i = this.itemSelectedIndex(item)

      /* if (this.module.includes('packets')) {
        if (this.notSetCheckbox(item)) return
      } */

      this.emitSelection(
        this.checkboxs
          ? i === -1
            ? [...this.selected, item] // Insert
            : this.selected.filter((v, i2) => i - i2) // Remove
          : [item]
      )
      this.afterSelected()
    },
    notSetCheckbox (item) {
      return (
        item.url ||
        ((item.status === 'Aguardando') !== this.$store.getters.isAdmin &&
          this.module === 'packets_topay')
      )
    },
    afterSelected () {
      // CREATE PACKAGE (SENDER, RECEIVER)
      const n = this.$route.name?.split('.')[1]
      if (['sender', 'deliveries'].includes(n)) {
        this.$store.dispatch('packageSave', {
          ...this.$store.state.packageSave,
          [n]: this.selected[0]
        })
      }
    },

    sort (key, direction) {
      this.sortBy = key
      this.sortDirection = direction
    },
    filterSwitch (value) {
      this.isFiltering = value
      if (!value) this.filterData = {}
    },
    filter (item, filters) {
      if (!this.isFiltering || Object.keys(filters).length === 0) {
        return true
      }

      for (const key of Object.keys(filters)) {
        if (
          item[key] !== undefined &&
          normalizeStr(item[key].toString()).includes(
            normalizeStr(filters[key])
          )
        ) {
          return true
        }
      }

      return false
    },
    onFiltered (filteredItems) {
      // console.log(filteredItems)
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    onEdit (id) {
      this.edit = this.edit !== id ? id : null
    },
    onSave (item, id) {
      this.edit = this.edit !== item ? item : null

      for (const [key, value] of Object.entries(item)) {
        item[key] = !value ? null : value
      }

      let form = item

      if (this.state.hasEdit) {
        form = Object.assign({}, form, {
          // group_id: form.groups[0].id,
          user_id: form.id
        })
      }

      let m = this.module.split('.')
      m = m[m.length - 1]

      if (m[m.length - 1] === 's') {
        m = m.substring(0, m.length - 1)
      }

      [
        'remember_token',
        'tenant_id',
        'deleted_at',
        'updated_at',
        'created_at'
      ].forEach((e) => delete form[e])

      console.log('FORM::::')
      console.log(form)
      api.post('auth/edit', /* + id, */ form).then(
        (response) => {
          console.log(response)
          if (response.status === 200) {
            /* this.$store.dispatch(
              m + 's/edit',
              form
            ) */
            toast(
              this,
              'success',
              'Usuário atualizado',
              'Nenhum problema foi encontrado.'
            )
          }
        },
        (error) => {
          toast(
            this,
            'danger',
            'Usuário não pode ser atualizado',
            'Problemas encontrados' +
                (error.response.status === 422
                  ? ' => ' + error.response.data.errors
                  : '')
          )
          return Promise.reject(error)
        }
      )
    },
    doPremium (user) {
      api.post('/user/redirectorPremium', { id: user.id }).then(
        (response) => {
          console.log(response)
          if (response.status === 200) {
            this.$store.dispatch('redirectors/edit', response.data.user)
            toast(
              this,
              'success',
              'Redirecionador Premium',
              'Redirecionador ' +
                response.data.user.name +
                ' passou para premium'
            )
          }
        },
        (error) => {
          toast(this, 'danger', 'Erro', errorFormater(error))
          return Promise.reject(error)
        }
      )
    },
    listR (user) {
      this.modalRedirectors = true
      this.redirectorSelected = user
    },
    undoPremium (user) {
      api.post('/user/redirectorPremiumRemove', { id: user.id }).then(
        (response) => {
          console.log(response)
          if (response.status === 200) {
            this.$store.dispatch('redirectors/edit', response.data.user)
            toast(
              this,
              'success',
              'Redirecionador Premium',
              'Redirecionador ' +
                response.data.user.name +
                ' não é mais premium'
            )
          }
        },
        (error) => {
          toast(this, 'danger', 'Erro', errorFormater(error))
          return Promise.reject(error)
        }
      )
    },
    remove (item) {
      if (
        !confirm(
          'Tem certeza que deseja deletar? ATENÇÃO: deletar um dado pode resultar na perda de outros que dependem dele'
        )
      ) {
        return
      }

      const user = Object.assign({}, item, {
        groups: [
          {
            pivot: {
              user_id: item.id
            }
          }
        ]
      })
      console.log(user, item)

      api.delete('user/delete?user_id=' + item.id).then(
        (response) => {
          console.log(response)
          if (response.status >= 200 && response.status <= 300) {
          /*   this.$store.dispatch(
              (this.module.includes('packets') ? 'packets' : this.module) +
                '/remove',
              item.id
            ) */
            toast(this, 'success', 'Deletar', 'Deletado com sucesso')
          }
        },
        (error) => {
          toast(this, 'danger', 'Erro', errorFormater(error))
          return Promise.reject(error)
        }
      )
      console.log(user)
    },
    openLink (item) {
      this.pay_selected = item

      if (!item.paid_out) {
        this.modalPay = true
        return
      }

      if (item.url) {
        window.open(item.url, '_blank')
      }
    },
    goEditPacket (item) {
      this.$store.dispatch(
        'packageSave',
        packetToSave(this.$store.state, item)
      )
      this.$router.push({ path: 'sendbox/resume' })
    },
    toggleModal (item) {
      this.userSelected = item
      this.modalShow = !this.modalShow
    },

    toggleModalTable (item) {
      this.userSelected = item
      this.modalTable = !this.modalTable
    },

    toggleModalItems () {
      this.modalItems = !this.modalItems
    },

    firstSelect () {
      this.isFiltering = false
      this.filterData = {}
      this.sortBy = ''
      console.log(this.$refs.table.$el.querySelectorAll('tr')[1])
      const self = this
      setTimeout(function () {
        self.$refs.table.$el.querySelectorAll('tr')[1].click()
      }, 1500)
    },
    csv () {
      const fields =
        'status, remessa, pagamento, valor_xprex, número_pacote, serviço_pacote, código_de_rastreio, modalidade_pacote, tipo_pacote, nome_remetente, email_remetente, telefone_remetente, nome_destinatário, email_destinatário, telefone_destinatário, data_de_criação, valor_declarado, última_atualização, nome_redirecionador'
      const data = this.items.reduce(
        (file, line) =>
          file +
          line.status +
          ', ' +
          line.code_shimpment +
          ', ' +
          (line.paid_out || 'não pago') +
          ', ' +
          line.internal_value +
          ', ' +
          line.packet_number +
          ', ' +
          line.service +
          ', ' +
          (line.tracking || 'não gerado') +
          ', ' +
          line.tax_modality +
          ', ' +
          line.type_package +
          ', ' +
          line.sender_name +
          ', ' +
          line.sender_email +
          ', ' +
          line.sender_telephone +
          ', ' +
          line.receiver_name +
          ', ' +
          line.receiver_email +
          ', ' +
          line.receiver_telephone +
          ', ' +
          line.created_at +
          ', ' +
          line.value +
          ', ' +
          line.updated +
          ', ' +
          (line.redirector_name || '') +
          '\n',
        fields + '\n'
      )
      console.log(data)

      const e = document.createElement('a')
      e.setAttribute(
        'href',
        'data:text/plain;charset=utf-8,' + encodeURIComponent(data)
      )
      e.setAttribute(
        'download',
        'packets-' +
          (this.module === 'packets_topay' ? 'topay' : 'paid') +
          '-' +
          new Date().toJSON().slice(0, 10).replace(/-/g, '/') +
          '.csv'
      )

      e.style.display = 'none'
      document.body.appendChild(e)

      e.click()

      document.body.removeChild(e)
    }
  }
}
</script>
