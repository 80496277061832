// import config from "@/config";

export default {
  namespaced: true,
  state: {
    // Info
    senders: [],

    loading: false,
    title: 'Clientes',
    itemsKey: 'sender',
    // externalModule: 'sender',
    btnLabel: 'Próximo',
    // linkTo: 'home.sender',
    hasEdit: true,
    createPacket: true,
    radios: true,
    dynamic: true,
    groupName: 'Sender',
    externalModule: 'sender',
    // API Get index
    get: {
      // urn: "user/index?group_ids=['4711cc38-9622-4000-8b7c-cb0f089a59d9']",
      urn: 'user/index?group_key=[Sender]',
      response: 'users'
    },
    get2: {
      // eslint-disable-next-line quotes
      urn: `group/public/index?group_key=Sender`,
      response: 'groups'
    },

    // API routes
    routes: [
      {
        key: 'add',
        form: 'base',
        title: 'Add New Sender',
        success: 'New Sender has been registered',
        urn: 'sender/create',
        response: 'sender',
        method: 'post',
        dispatch: true
      }
    ],
    hasExtraField: true,
    fields: [
      { key: 'name', label: 'Nome' },
      { key: 'email', label: 'Email' },
      { key: 'cpf', label: 'CPF' },
      { key: 'telephone', label: 'Telefone' }
    ],
    form: {}
  },
  mutations: {
    set: (state, payload) => {
      state.sender = payload
      state.loading = false
    },
    unshift: (state, payload) => {
      state.sender.unshift(payload)
    },
    swap: (state, payload) => {
      state.sender.forEach((element, index) => {
        if (element.id === payload.id) {
          state.sender.splice(index, 1, payload)
        }
      })
    },
    remove: (state, payload) => {
      state.sender = state.sender.filter((a) => a.id !== payload)
    }
  },
  actions: {
    init: (context, payload) => {
      context.commit('set', payload)
    },
    add: (context, payload) => {
      context.commit('unshift', payload)
    },
    edit: (context, payload) => {
      context.commit('swap', payload)
    },
    remove: (context, payload) => {
      context.commit('remove', payload)
    }
  }
}
