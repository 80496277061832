// import config from "@/config";

export default {
  namespaced: true,
  state: {
    sender: [],
    loading: false,
    title: 'Lojas',
    itemsKey: 'sender',
    // externalModule: 'sender',
    btnLabel: 'Próximo',
    // linkTo: 'home.sender',
    hasEdit: true,
    createPacket: true,
    radios: true,
    dynamic: true,
    groupName: 'Sender',
    // API Get index
    get: {
      // urn: "user/index?group_id=c316a0c3-7d83-4fab-9638-6dd9704a7cb9",
      // eslint-disable-next-line quotes
      urn: 'user/index/?group_key=[Sender]',
      response: 'users'
    },
    get2: {
      // eslint-disable-next-line quotes
      urn: `group/public/index?group_key=['Sender']`,
      response: 'groups'
    },
    hasExtraField: true,
    fields: [
      { key: 'name', label: 'Nome' },
      { key: 'email', label: 'Email' },
      { key: 'cpf', label: 'CPF' },
      { key: 'telephone', label: 'Telefone' }
    ]
  },
  forms: {},
  mutations: {
    set: (state, payload) => {
      state.sender = payload
      state.loading = false
    },
    unshift: (state, payload) => {
      state.sender.unshift(payload)
    },
    swap: (state, payload) => {
      state.sender.forEach((element, index) => {
        if (element.id === payload.id) {
          state.sender.splice(index, 1, payload)
        }
      })
    },
    remove: (state, payload) => {
      state.sender = state.sender.filter((a) => a.id !== payload)
    }
  },
  actions: {
    init: (context, payload) => {
      context.commit('set', payload)
    },
    add: (context, payload) => {
      context.commit('unshift', payload)
    },
    edit: (context, payload) => {
      context.commit('swap', payload)
    },
    remove: (context, payload) => {
      context.commit('remove', payload)
    }
  }
}
