export default {
  namespaced: true,
  state: {
    deliveries: [],
    loading: false,
    title: 'Entregadores',
    itemsKey: 'deliveries',
    // externalModule: 'deliveries',
    // linkTo: 'users.deliveries',
    btnLabel: 'Próximo',
    hasEdit: true,
    createPacket: true,
    radios: true,
    groupName: 'Deliveries',
    // API Get index
    get: {
      // urn: "user/index?group_id=c316a0c3-7d83-4fab-9638-6dd9704a7cb9",
      // eslint-disable-next-line quotes
      urn: 'user/index/?group_key=[Deliveries]',
      response: 'users'
    },
    get2: {
      // eslint-disable-next-line quotes
      urn: `group/public/index?group_key=['Deliveries']`,
      response: 'groups'
    },
    fields: [
      { key: 'name', label: 'Nome' },
      { key: 'email', label: 'Email' },
      { key: 'cpf', label: 'CPF' },
      { key: 'telephone', label: 'Telefone' }
    ],
    hasExtraField: true,
    dynamic: true
  },
  mutations: {
    set: (state, payload) => {
      state.deliveries = payload
      state.loading = false
    },
    unshift: (state, payload) => {
      state.deliveries.unshift(payload)
    },
    swap: (state, payload) => {
      state.deliveries.forEach((element, index) => {
        if (element.id === payload.id) {
          state.deliveries.splice(index, 1, payload)
        }
      })
    },
    remove: (state, payload) => {
      state.deliveries = state.sendersfilter((a) => a.id !== payload)
    }
  },
  actions: {
    init: (context, payload) => {
      context.commit('set', payload)
    },
    add: (context, payload) => {
      context.commit('unshift', payload)
    },
    edit: (context, payload) => {
      context.commit('swap', payload)
    },
    remove: (context, payload) => {
      context.commit('remove', payload)
    }
  }
}
