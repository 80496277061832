<template>
      <div id="navbar" class="navbar navbar-end" v-if="!$store.getters.isLogged">
        <template v-if="$route.name !== ['Privacidade','Login','Dashboard']">
          <a href="#corrida" class="navbar-item" title="Início">
              Solicitar corrida
            </a>

            <a href="#entrega" class="navbar-item" title="Sobre nós">
              Fazer entrega
            </a>

            <a href="#comunidade" class="navbar-item" title="Como funciona">
              Comunidade
            </a>
        </template>

    <a @click="$route.name !== 'Sobre'? $router.push('/sobre#duvidas'): null" href="#duvidas" class="navbar-item" title="Dúvidas frequentes">
      FAQ
    </a>
     <slot>
      <!-- nesse slot fica o botão de "download" -->
    </slot>
    </div>
</template>

<script>
export default {
  computed: {
    hasUser () {
      return !!this.user
    }
  }
}
</script>
<style lang="stylus">
@import '../../style/colors.styl';
@import '../../style/dimens.styl';
// @import '../../style/fonts.styl';
@import '../../style/mixins.styl';

// ----------------------------------
// Dimensions
// ----------------------------------
logoPadding = 5px;
logoPadding-sm = logoPadding * topbar-sm-resize;
height-sm = topbar-height * topbar-sm-resize;

// landing page styles
.navbar-end {
  width: inherit
  display: flex;
    justify-content: flex-end;
    margin-left: auto;
    align-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    padding: 5%
    }

    .navbar-item, .navbar-link {
      align-items: center;
    display: flex;
    justify-content: space-between;
    align-content: center;
    flex-wrap: wrap;
    padding: 3%;
    flex-direction: row;
    cursor: pointer
    text-decoration: none
    color: light
    }

@media (max-width: lg) {
  #navbar{
    display: none
}

  .menu-display{
    display: flex !important;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 90%;
    left: 0;
    padding: 8%;
    background-color: #fff;
    }
}
</style>
