// import config from "@/config";

export default {
  namespaced: true,
  state: {
    // Info
    deliveries: [],

    loading: false, // when request is call turn this to true
    itemsKey: 'deliveries',
    externalModule: 'deliveries',
    title: 'Entregadores',
    name: 'Entregadores',
    hasEdit: true,
    createPacket: true,
    radios: true,
    dynamic: true,
    groupName: 'deliveries',
    // API Get index
    get: {
      // urn: "user/index?group_ids=['4711cc38-9622-4000-8b7c-cb0f089a59d9']",
      urn: 'user/index/?group_key=[Deliveries]',
      response: 'users'
    },

    get2: {
      // eslint-disable-next-line quotes
      urn: `group/public/index?group_key=Deliveries`,
      response: 'groups'
    },
    // API routes
    routes: [
      {
        key: 'add',
        form: 'base',
        title: 'Add New Sender',
        success: 'New Sender has been registered',
        urn: 'sender/create',
        response: 'sender',
        method: 'post',
        dispatch: true
      }
    ],
    hasExtraField: true,
    fields: [
      { key: 'name', label: 'Nome' },
      { key: 'email', label: 'Email' },
      { key: 'cpf', label: 'CPF' },
      { key: 'telephone', label: 'Telefone' }
    ],
    form: {}
  },
  mutations: {
    set: (state, payload) => {
      state.deliveries = payload
      state.loading = false
    },
    unshift: (state, payload) => {
      state.deliveries.unshift(payload)
    },
    swap: (state, payload) => {
      state.deliveries.forEach((element, index) => {
        if (element.id === payload.id) {
          state.deliveries.splice(index, 1, payload)
        }
      })
    },
    remove: (state, payload) => {
      state.deliveries = state.deliveries.filter((a) => a.id !== payload)
    }
  },
  actions: {
    init: (context, payload) => {
      context.commit('set', payload)
    },
    add: (context, payload) => {
      context.commit('unshift', payload)
    },
    edit: (context, payload) => {
      context.commit('swap', payload)
    },
    remove: (context, payload) => {
      context.commit('remove', payload)
    }
  }
}
