<template>
  <div>
    <Topbar :user="user"></Topbar>
    <div class="frame">
      <Sidebar>
        <div class="content">
          <transition name="page-transition" mode="out-in">
            <router-view :name="$route.fullPath"></router-view>
          </transition>
        </div>z
      </Sidebar>
      <div class="content">
        <Table v-if="$route.path !== '/'" :module="$route.name.split('.')[1]"></Table>
        <Home></Home>
      </div>
    </div>
  </div>
</template>

<style scoped lang="stylus">
@import '../../style/colors.styl';
@import '../../style/mixins.styl';
@import '../../style/dimens.styl';

.frame {
  display: flex;

  &:last-child {
    flex-grow: 1;
  }

  .content {
    width: 80%;
    margin: 5%
  }
}

.page-transition-enter, .page-transition-leave-to {
  // transform translateX(-30px)
  opacity: 0;
}

.page-transition-enter-active, .page-transition-leave-active {
  transition: all 0.2s;
}
</style>

<script>
import Topbar from '@/components/Topbar'
import Sidebar from '@/components/Sidebar'
import Table from './Table.vue'
import { api } from '@/services'
import { toast } from '../../utils'
import Home from './Home'
import store from '../../store/index'

export default {
  name: 'Dashboard',
  components: { Topbar, Sidebar, Table, Home },
  computed: {
    user () {
      return { name: this.$store.getters.userName, group: 'Nome do grupo' }
    },
    isDeniedByRole () {
      return store.getters.rules.includes('index.user.api')
    }
  },
  methods: {
    getData () {
      this.$store.state.dashboard
        .filter((a) => !a.noMenu)
        .forEach((m) => {
          const info = this.$store.state[m.key].get
          if (info.type === 'post') {
            api.post(info.urn, info.body).then(
              (response) => {
                if (response.status === 200) {
                  this.$store.dispatch(
                    m.key + '/init',
                    response.data[info.response]
                  )
                }
              },
              (error) => {
                toast(this, 'danger', `Erro ao obter ${m.title}`, error.message)
                return Promise.reject(error)
              }
            )
          } else {
            api.get(info.urn).then(
              (response) => {
                if (response.status === 200) {
                  this.$store.dispatch(
                    m.key + '/init',
                    response.data[info.response]
                  )
                }
              },
              (error) => {
                toast(this, 'danger', `Erro ao obter ${m.title}`, error.message)
                return Promise.reject(error)
              }
            )
          }
          /*  if (this.$store.state[m.key].get2) {
            const info = this.$store.state[m.key].get2

            api.get(info.urn).then(
              (response) => {
                if (response.status === 200) {
                  this.$store.dispatch(
                    m.key + '/init',
                    response.data[info.response]
                  )
                }
              },
              (error) => {
                toast(
                  this,
                  'danger',
                  `Erro ao obter ${m.title}`,
                  error.message
                )
                return Promise.reject(error)
              }
            )
          } */
        })
    },
    getGroups () {
      api.get('group/index').then((response) => {
        console.log(response.data)
        if (response.status === 200) {
          this.$store.dispatch('groups/init', response.data.groups)
        }
      }, error => {
        toast(this, 'danger', 'Erro ao obter users', error.message)
        return Promise.reject(error)
      })
    },
    /*  getExtraFields () {
      this.$store.state.extrafield.keys.forEach((e) => {
        api
          .get(
            `extra_field/useringroup?group_key=[${e}]`
          )
          .then(
            (response) => {
              if (response.status >= 200 && response.status < 300) {
                console.log(response.data)
                this.$store.dispatch('extrafield/init', {
                  group: e,
                  extrafields: response.data.extra_fields
                    .sort((a, b) => (a.order || 999) - (b.order || 999))
                    .map((e) => ({ ...e, col: 3 }))
                })
              }
            },
            (error) => {
              toast(
                this,
                'danger',
                `Erro ao obter extrafields do grupo ${e}`,
                error.message
              )
              return Promise.reject(error)
            }
          )
      })
    }, */
    callModal (modal, item, action, module) {
      this.item = item
      this.action = action
      this.module = module
      this.$bvModal.show(modal)
    }
  },
  created () {
    api.get('role/index').then((response) => {
      if (response.status === 200) {
        this.$store.dispatch('rules', response.data.roles)
        const r = (k) => k.key.includes('index.user.api') || store.getters.rules.length > 0
        if (store.getters.rules.some(r)) {
          this.getData()
          this.getGroups()
        }
      }
    }, error => {
      toast(this, 'danger', 'Erro ao obter regras', error.message)
      return Promise.reject(error)
    })
  }
  /*  mounted () {
    const r = (k) => k.key.includes('index.user.api')
    if (store.getters.rules.some(r)) {
      this.getData()
      this.getGroups()
    }
    console.log(store.getters.rules.some(r))
  } */
}
</script>
