<template>
  <div v-if="$route.path === '/'"
    class="main-row"
  >
    <b-row>
<!--       <b-col cols="2">
        <div @click="reset">
          <div class="box">
            <img src="@/assets/images/box-icon.svg" />
            <span>Enviar Pacote</span>
          </div>
        </div>
      </b-col> -->
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'Home',
  methods: {
    reset () {
      this.$store.dispatch('packageSave', {})
      this.$router.push({ name: 'usuarios.sender' })
    }
  }
}
</script>

<style lang="stylus" scoped>

@import '../../style/colors.styl';

.main-row
    padding 2rem 3rem

    a:hover
        text-decoration none

    .box
        display inline-grid
        background #414D55
        padding 2rem 3rem
        color #C4C4C4
        border-radius .5rem
        justify-content center
        align-items center
        cursor pointer
        transition 0.4s
        margin-bottom 1.5rem

        img
            margin-left 13px

        &:hover
            background grey

        span
            margin-top 15px
            text-align center
            font-weight bold
</style>
