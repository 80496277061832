<template>
  <b-form @submit="onSubmit" ref="form">
    <b-row>
      <b-col v-for="field in fieldsExtra" :key="field.key" :cols="field.col">
        <b-form-group :id="'input-add-group' + field.key">
          <div class="h-separator">
            <label :for="'input-add-' + field.key" class="title"
              >{{ field.label }}{{ field.required ? " *" : "" }}</label
            >
            <span></span>
          </div>
          <template v-if="field.key === 'cep' || field.key === 'zip'">
            <b-form-input
              v-if="['text', 'email', 'tel', 'number'].includes(field.type)"
              :id="'input-add-' + field.key"
              v-model="form[field.key]"
              :type="field.type"
              v-maska="field.mask"
              :placeholder="field.label"
              :required="field.required"
              @blur="searchCep"
            ></b-form-input>
          </template>
          <template v-else>
            <b-form-input
              v-if="['text', 'email', 'tel', 'number'].includes(field.type)"
              :id="'input-add-' + field.key"
              v-model="form[field.key]"
              :type="field.type"
              v-maska="field.mask"
              :placeholder="field.label"
              :required="field.required"
            ></b-form-input>

            <b-form-select
              v-if="field.type === 'select'"
              :id="'input-add-' + field.key"
              :placeholder="field.label"
              v-model="form[field.key]"
              :options="field.options"
              :required="field.required"
            >
              <template #first>
                <b-form-select-option :value="undefined" disabled
                  >Por favor, selecione uma opção</b-form-select-option
                >
              </template>
            </b-form-select>
          </template>
        </b-form-group>
      </b-col>
    </b-row>

    <b-button type="submit" variant="primary">Salvar</b-button>
  </b-form>
</template>

<script>
import api from '@/services'
import { toast, errorFormater } from '@/utils'
import validateDoc from '@/validate'

export default {
  props: {
    group: {
      type: String,
      required: true
    },
    module: {
      type: String,
      required: true
    },
    modal: {
      type: String,
      required: true
    },
    fields: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      form: {},
      options_extra: []
    }
  },
  computed: {
    fieldsExtra () {
      return [
        ...this.fields.map((f) => ({ ...f, required: !!1 })),
        ...this.$store.state.extrafield.groups[this.group].map((e) => ({
          // format to table field
          key: e.key,
          label: e.description,
          type: e.children.length ? 'select' : 'text',
          options: e.children.map((v) => ({
            text: v.description,
            value: v.description
          })),
          col: e.col,
          required: !!e.required
        }))
      ]
    }
  },
  methods: {
    searchCep (e) {
      console.log(this.module)
      const value = e.target.value
      if (value) {
        api.get('endereco/' + value.replaceAll('-', '')).then(
          (response) => {
            console.log(response)
            if (response.status === 200) {
              const address = response.data?.results[0]?.formatted_address
              if (address) {
                // BR
                if (value.replaceAll('-', '').length >= 8) {
                  const v = address.split(' - ')
                  if (this.module !== 'senders') { this.form.endereco = v[0].trim() }
                  this.form.cidade = v[1].split(', ')[1].trim()
                  this.form.uf = v[2].split(', ')[0].trim()
                  this.form.pais = 'Brasil'
                // eslint-disable-next-line brace-style
                }

                // USA
                else {
                  const v = address.split(', ')
                  if (this.module !== 'senders') {
                    this.form.endereco = v[0].trim()
                    this.form.cidade = v[1].trim()
                    this.form.uf = v[2].trim().split(' ')[0].trim()
                    this.form.pais = 'Estados Unidos'
                  }
                }

                this.form = { ...this.form }
              } else {
                if (this.module !== 'senders') this.form.endereco = undefined
                this.form.cidade = undefined
                this.form.uf = undefined
                this.form.pais = undefined
                this.form = { ...this.form }
                toast(
                  this,
                  'info',
                  'Endereço',
                  'Nenhum endereço foi encontrado com esse cep/zip'
                )
              }
            }
          },
          (error) => {
            return Promise.reject(error)
          }
        )
      }
    },
    updatePassword (e) {
      this.form.password = e
      this.form.password_confirmation = e
    },
    onSubmit (event) {
      event.preventDefault()
      console.log(this.group)

      if (this.group !== 'sender') {
        if (!validateDoc(this.form?.cpf)) {
          toast(this, 'info', 'CPF inválido', 'o CPF ou CNPJ não é válido')
          return
        }
      }

      api.post('auth/create', { ...this.form, group_key: this.group }).then(
        (response) => {
          if (response.status >= 200 && response.status < 300) {
            this.$store.dispatch(this.module + '/add', response.data.user)

            // Hide the modal manually
            this.$nextTick(() => {
              this.$bvModal.hide(this.modal)
            })

            this.$emit('finish')

            toast(
              this,
              'success',
              'Usuário registrado',
              'Usuário foi registrado com sucesso'
            )
          }
        },
        (error) => {
          toast(this, 'danger', 'Usuário não registrado', errorFormater(error))
          return Promise.reject(error)
        }
      )
    }
  }
}
</script>
<style lang="stylus" scoped>
@import '../../style/colors.styl';

.h-separator {
  display: flex;

  .title {
    padding: 0px 10px;
    font-size: 14px;
    color: light;
    font-weight: bold;
    border-radius: 1rem;
    background: primary;
  }

  span {
    align-self: center;
  }

  span:last-child {
    border: 1px dashed primary;
    height: 1px;
  }

  span:last-child {
    flex-grow: 1;
  }
}

.btn {
  justify-content: space-between;
  margin-left: 10px;
  float: right;
}
</style>
