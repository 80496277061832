<template>
  <div>
    <div class="interface-header">
      <!-- Button Action -->
      <template v-if="!noBtn">
        <template v-if="!buttonDisabled">
          <div
            v-if="btnAsEvent"
            @click="$emit('action', $event)"
            class="title-btn"
          >
            <i class="icon-btn material-icons">{{ btnIcon }}</i>
            <span>{{ btnLabel }}</span>
          </div>
          <router-link v-else :to="btnLinkTo" tag="div" class="title-btn">
            <i class="icon-btn material-icons">{{ btnIcon }}</i>
            <span>{{ btnLabel }}</span>
          </router-link>
        </template>
        <template v-else>
          <div class="title-btn btn-loading">
            <i class="icon-btn material-icons">loop</i>
            <span>Carregando...</span>
          </div>
        </template>
      </template>
    </div>
    <div class="interface-body">
      <div class="submenu-title">
        <span>{{ title }}</span>
      </div>
      <div class="d-flex flex-row tab" v-if="hasTabs">
        <router-link tag="div" :to="tab1.to" active-class="tab-actived">{{
          tab1.name
        }}</router-link>
        <router-link tag="div" :to="tab2.to" active-class="tab-actived">{{
          tab2.name
        }}</router-link>
      </div>
      <!-- Interface Header -->
      <div class="interface-header" v-if="!noHeader">
        <div
          class="interface-header-wrapper"
          :style="hasTabs ? '' : 'border-radius: 1rem 1rem 0 0;'"
        >
          <b-button
            v-b-modal.modal-1
            variant="success"
            v-if="currentRouteName == 'sendbox.sender'"
          >
            <i class="material-icons">add</i>
          </b-button>
          <b-button
            v-b-modal.modal-2
            variant="success"
            v-if="currentRouteName == 'sendbox.receiver'"
          >
            <i class="material-icons">add</i>
          </b-button>
          <b-button
            v-b-modal.modal-3
            variant="success"
            v-if="currentRouteName == 'sendbox.items'"
          >
            <i class="material-icons">add</i>
          </b-button>
          <span
            @click="filterSwitch"
            :class="'filter' + (filter ? ' actived' : '')"
            ><i class="material-icons-outlined">filter_alt</i>Filtrar</span
          >
          <span class="v-separator"></span>
          <b-form-select
            id="sort-by-select"
            @change="$emit('sort', $event, direction)"
            :options="sortOptions"
            v-model="sortBy"
            class="select"
          >
            <template #first>
              <b-form-select-option :value="undefined" disabled
                >Ordenar</b-form-select-option
              >
            </template>
          </b-form-select>
          <div class="direction" @click="toggleDirection">
            <transition name="rotate">
              <i v-bind:key="direction" class="material-icons-outlined">{{
                direction ? "arrow_upward" : "arrow_downward"
              }}</i>
            </transition>
          </div>
        </div>
      </div>

      <div
        class="h-separator"
        v-if="separator"
        :style="noHeader ? 'border-radius: 1rem; padding-top: 16px' : ''"
      >
        <span></span>
        <template v-if="paths">
          <template :v-for="p in paths">
              <router-link
              :to="{ name: p.route }"
              custom
              v-slot="{ navigate, isActive }"
              :key="p.name"
            >
              <span
                :class="'title ' + (isActive ? 'active-title' : '')"
                @click="isAbleContinue(navigate, $event)"
                >{{ p.name }}
                <p v-html="validate(p)"></p
              ></span>
            </router-link>
            <span :key="p.name + '-separator'"></span>
          </template>
        </template>
        <template v-else>
          <span class="title">{{ title }}</span>
          <span></span>
        </template>
      </div>

      <div class="slot-content">
        <slot></slot>
      </div>
      <!--remove the hide-header-close-->
      <b-modal size="xl" centered id="modal-1">
        <template #modal-header></template>
        <div class="h-separator">
          <span></span>
          <span class="title">Remetente</span>
          <span></span>
        </div>
        <Form
          :fields="formSender"
          group="sender"
          module="senders"
          modal="modal-1"
          @finish="firstSelect"
        />
        <template #modal-footer>
          <span></span>
        </template>
      </b-modal>
      <b-modal size="xl" centered id="modal-2">
        <template #modal-header></template>
        <div class="h-separator">
          <span></span>
          <span class="title">Destinatário</span>
          <span></span>
        </div>
        <Form
          :fields="formReceiver"
          group="receiver"
          module="receivers"
          modal="modal-2"
          @finish="firstSelect"
        />
        <template #modal-footer>
          <span></span>
        </template>
      </b-modal>
      <b-modal size="xl" centered id="modal-3">
        <template #modal-header> </template>
        <ItemForm @finish="firstSelect" />
        <template #modal-footer>
          <span></span>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import ItemForm from '@/components/Modals/Item'
import Form from '@/views/Dashboard/Form'
export default {
  data: () => ({
    sortBy: undefined,
    filter: false,
    direction: true, // up true = asc, down false = desc
    form: {},
    p: '',

    /// //////////////////////////
    formReceiver: [
      { key: 'name', label: 'Nome Completo', type: 'text', col: '5' },
      {
        key: 'telephone',
        label: 'Telefone',
        type: 'tel',
        col: '3',
        mask: [
          '+# (###) ##-##-##',
          '+## (##) #####-####',
          // "+## (##) ####-####",
          '+# ###-###-####'
        ]
      },
      { key: 'email', label: 'Email', type: 'email', col: '4' },
      {
        key: 'cpf',
        label: 'CPF',
        type: 'text',
        col: '3',
        mask: ['###.###.###-##', '##.###.###/####-##']
      }
    ],
    formSender: [
      { key: 'name', label: 'Nome Completo', type: 'text', col: '5' },
      {
        key: 'telephone',
        label: 'Telefone',
        type: 'tel',
        col: '3',
        mask: [
          '+# (###) ##-##-##',
          '+## (##) #####-####',
          // "+## (##) ####-####",
          '+# ###-###-####'
        ]
      },
      { key: 'email', label: 'Email', type: 'email', col: '4' }
    ]
    /// //////////////////////////
  }),
  components: {
    Form,
    ItemForm
  },
  methods: {
    filterSwitch () {
      this.filter = !this.filter
      this.$emit('filter', this.filter)
    },
    firstSelect () {
      this.$emit('firstSelect')
    },
    isAbleContinue (go, e) {
      go(e)
    },
    validate (p) {
      return p ? '&check;' : '' // "&#10005;";
    },
    toggleDirection () {
      this.direction = !this.direction
      if (this.sortBy) {
        this.$emit('sort', this.sortBy, this.direction)
      }
    }
  },
  props: {
    // Main Title
    title: { type: String, required: true },
    paths: { type: Array, required: false },

    // Button
    noBtn: { type: Boolean, default: false },
    buttonDisabled: { type: Boolean, default: false },
    btnLabel: String,
    btnIcon: String,
    btnAsEvent: { type: Boolean, default: false }, // if true btnLinkTo is not used
    btnLinkTo: Object,

    // Interface Header
    noHeader: { type: Boolean, default: false }, // true for not display header, filter and order by will not be trigger
    noModal: { type: Boolean, default: false },
    separator: { type: Boolean, default: false },
    sortOptions: Array,

    // Tab
    hasTabs: { type: Boolean, default: false },
    tab1: { type: Object, default: () => ({}) },
    tab2: { type: Object, default: () => ({}) }
  },
  computed: {
    currentRouteName () {
      return this.$route.name
    }
  }
}
</script>

<style lang="stylus" scoped>

@import '../../../style/colors.styl';
@import '../../../style/fonts.styl';
@import '../../../style/mixins.styl';

.rotate-enter
    transform rotate(-180deg)

.rotate-leave-to, .rotate-leave-active
    display none

.rotate-enter-active
    transition all 0.3s ease-in-out
    //transform rotate(180deg)

.btn-success
    border-radius 50%
    padding 5px 6px
    margin-right 30px

.interface-header
    //margin-bottom 16px
    background-color primary

    h2
        font-family OpenSans

    span
        font-family Calibri

    h2, div
        display inline-block
        vertical-align middle

    .interface-header-wrapper
        background-color light
        border-radius 0 1rem 0 0
        width 100%
        padding 16px

.tab
    > div
        padding 12px 78px
        font-size 1.2em
        cursor pointer
        transition 0.25s
        color rgba(0,0,0,.70)
        background-color alpha(light, 70%)

        &:hover
            color rgba(0,0,0,.9)
            background-color alpha(light, 90%)

    .tab-actived
        color rgba(0,0,0,1)
        background-color: light

    background-color: primary

.interface-body
    background-color content-theme()
    //padding 4px
    border-radius 8px
    color color-theme(true)

    .submenu-title
        position absolute
        margin-top -3.5rem
        color primary
        font-size 24px
        font-weight bold

    .interface-header

        span
            font-size 21px
            vertical-align middle
            cursor pointer

            &:hover
                color primary

        .actived
            color primary

        i
            font-size 28px
            vertical-align sub

        .direction
            vertical-align middle
            background-color primary
            border-radius 4px
            padding 4px
            cursor pointer
            margin-left 8px
            no-select()

            i
                vertical-align middle
                //font-size 1em
                color alpha(grey, 70%)

            &:hover
                i
                    color grey

        .v-separator
            //border 1px solid secondary
            background-color color-theme(true)
            display inline-block
            width 1px
            height 25px
            margin 0 8px
            cursor default

    .slot-content
        border-radius 0 0 1rem 1rem
        background-color light
        padding 0 16px 16px 16px

    .select
        width auto
        margin-left 8px

      .custom-select
        background-color primary
        color white
        border-color secondary

.h-separator

    background-color light
    display flex
    padding-bottom 16px

    .title
        padding 3px 10px
        font-size 14px
        text-transform uppercase
        color alpha(grey, 70%)
        font-weight bold
        border-radius 1rem
        background primary
        cursor pointer

        + span
            border 1px dashed greyDark
            height 1px
            width 50px

    .active-title
        color primary // lighten(lightBlue, 25%)

    span
        align-self center

    span:first-child, span:last-child
        border 1px dashed greyDark
        height 1px

    span:first-child
        width 20px

    span:last-child
        flex-grow 1

.title-btn
    float right
    background-color blue
    color white
    border-radius 5px
    padding 6px 30px
    cursor pointer
    transition 0.2s
    margin-top -4.5rem

    &:hover
        background-color alpha(primary, 0.8)

    span, .icon-btn
        vertical-align middle
        no-select()

.btn-loading
    background-color alpha(blue, 0.75)
    color alpha(white, 0.75)

.icon-btn
    font-size 1.25em
    padding-right 4px
    margin-left -4px

p
    margin unset
    display inline
</style>
